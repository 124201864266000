<template>
  <div class="container">
    <p class="text-h4">Delete your account</p>
    <p>This action is permanent and cannot be undone! After deleting your account, you will be logged out immediately and will not be able to access your listings or transactions anymore.</p>

    <v-btn color="error" @click="dialogSignIn = !dialogSignIn">
      Sign In for delete Account
    </v-btn>
    <v-dialog v-model="dialogSignIn" max-width="700">
      <v-card >
        <v-row class="ma-5">
          <div class="col-md-8">
                  <v-form ref="form" @submit.prevent="onSubmit" lazy-validation>
                    <div class="form-group">
                      <label class="text-dark" for="email">Correo electrónico:</label>
                      <div class="border border-info text-center p-2 rounded-pill-custom mr-3">
                          <i class="mdi mdi-account" aria-hidden="true"></i>
                          <!--<input type="email" style="width: calc(100% - 35px);" class="border-0 form-control d-inline-block" id="email" placeholder="Ingrese su correo electrónico">-->
                          <v-text-field name="email" label="Ingrese su correo electrónico" type="text" class="border-0 form-control d-inline-block" style="width: calc(100% - 35px);" v-model="account.email" :rules="nameRules" outlined flat solo></v-text-field>
                      </div>                      
                    </div>
                    <div class="form-group">
                      <label  class="text-dark" for="password">Contraseña:</label>
                      <div class="border border-info text-center p-2 rounded-pill-custom mr-3">
                          <i class="mdi mdi-lock" aria-hidden="true"></i>
                          <!--<input type="password" style="width: calc(100% - 35px);" class="border-0 form-control d-inline-block" id="password" placeholder="Ingrese su contraseña">-->
                          <v-text-field name="password" label="Ingrese su contraseña" :type="showPassword ? 'text' : 'password'" class="border-0 form-control d-inline-block" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword" style="width: calc(100% - 35px); border: none !important;" v-model="account.password" :rules="nameRules" outlined flat solo></v-text-field>
                      </div>                      
                    </div>                    
                    
                    <div class="text-right">
                      <v-btn color="#17a2b8" dark type="submit" class="btn btn-info rounded-pill-custom">Iniciar sesión</v-btn>
                    </div>
                  </v-form>
          </div>
          <hr>
              <div class="text-center">
                <v-btn elevation="4" class="mt-2" style="width: 80%;" outlined color="blue-grey darken-2" block @click="LoginWithGoogle">
                  <img width="30px" src="../../assets/admin/buscar.svg" class="mr-2">
                  <label>Iniciar sesión con Google</label>
                </v-btn>
              </div>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { db, functions } from '../../plugins/firebase'
export default {
    data() {
        return {
          dialogSignIn: false,
          nameRules: [
            v => (v && v.length >= 1) || 'You need to complete the field',
            v => (v && v.length <= 50) || 'Name must be less than 50 characters'
          ],
          account: {
            email: '',
            password: ''
          },
          showPassword: false,
        };
    },
    methods: {
      onSubmit(){

      },
      LoginWithGoogle () {
        let localStore =this.$store;
        let provider = new auth.GoogleAuthProvider();
        auth().signInWithPopup(provider).then(
          function (result) {
            console.log(result)
                // var token = result.credential.idToken;
                var user = result.user;

                //this is what you need
                var isNewUser = result.additionalUserInfo.isNewUser;
                if (isNewUser) {
                  console.log('usuario eliminado')
                    //delete the created user
                    result.user.delete();
                } else {
                    // your sign in flow
                    console.log('user ' + user.email + ' does exist!');
                    localStore.dispatch('loginGoogle').then(() => {                    
                      if (localStore.state.user.role == 'schoolTeacher') {                      
                        location.href = "schoolTeacher";
                      }else{
                        location.reload();
                      }
                    }).catch(error => {
                      console.log(error);
                      this.errorMessage = error;
                    });
                }
          }).catch(function (error) {
            console.log(error)
          });
      },
    },
}
</script>